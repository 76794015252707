// src/pages/Home.jsx
import React from 'react';
//import { useData } from '../hooks/useData';
import { useData } from 'hooks/userData.js';
//import { ArrowRight, Play, BarChart2, Cloud, Workflow, Check } from 'lucide-react';
import { Link } from 'react-router-dom';

// src/pages/Home.jsx
//import React from 'react';
//import { Link } from 'react-router-dom';
//import { useData } from '../hooks/useData';
import { 
  ArrowRight, 
  Play, 
  Cloud,
  GraduationCap,
  BookOpenText,
  TicketsPlane,
  BarChart2,
  Workflow,
  Shield,
  Users,
  Zap,
  Settings,
  Code 
} from 'lucide-react';

// Icon mapping object
const iconMap = {
  Cloud: Cloud,
  GraduationCap: GraduationCap,
  BookOpenText: BookOpenText,
  TicketsPlane: TicketsPlane,
  BarChart2: BarChart2,
  Workflow: Workflow,
  Shield: Shield,
  Users: Users,
  Zap: Zap,
  Settings: Settings,
  Code: Code
};

const Home = () => {
  const { data, loading, error } = useData('home');

  const getIcon = (iconName) => {
    const IconComponent = iconMap[iconName] || Cloud; // Default to Cloud if icon not found
    return <IconComponent size={24} className="text-indigo-600" />;
  };

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-600">Error: {error}</div>;

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative min-h-[80vh] flex items-center">
        {/* Video Background */}
        <div className="absolute inset-0 w-full h-full overflow-hidden">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute min-w-full min-h-full object-cover"
          >
            <source src="/videos/hero-background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>

        {/* Content */}
        <div className="relative z-10 max-w-6xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              {data?.hero?.title}
            </h1>
            <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
              {data?.hero?.subtitle}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/features"
                className="inline-flex items-center justify-center px-8 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
              >
                {data?.hero?.cta}
                <ArrowRight className="ml-2" size={20} />
              </Link>
              {/* <button className="inline-flex items-center justify-center px-8 py-3 bg-white text-indigo-600 rounded-md border border-transparent hover:bg-opacity-90 transition-colors">
                <Play className="mr-2" size={20} />
                {data?.hero?.secondaryCta}
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {data?.features?.map((feature) => (
              <div 
                key={feature.id} 
                className="p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow group"
              >
                <div className="w-12 h-12 bg-indigo-50 rounded-lg flex items-center justify-center mb-4 group-hover:bg-indigo-100 transition-colors">
                  {getIcon(feature.icon)}
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            {data?.stats?.map((stat, index) => (
              <div key={index} className="p-6">
                <div className="text-4xl font-bold text-indigo-600 mb-2">{stat.value}</div>
                <div className="text-xl font-semibold mb-2">{stat.label}</div>
                <div className="text-gray-600">{stat.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Clients Section */}
     {/*<div className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-center mb-12">Trusted by Industry Leaders</h2>
          <div className="grid md:grid-cols-3 gap-12 items-center">
            {data?.clients?.map((client, index) => (
              <div key={index} className="flex justify-center">
                <div className="h-12 flex items-center justify-center text-gray-400">
                  {client.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
     {/* Final CTA Section */}
     <div className="bg-indigo-600 py-20">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Revolutionize your Future?
          </h2>
          <p className="text-xl text-indigo-100 mb-8">
            Join thousands of users that trust our platform
          </p>
          <a
             href="https://forms.gle/wroxMHstd6rvy64d9" 
             target="_blank" rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-8 py-3 bg-white text-indigo-600 rounded-md hover:bg-indigo-50 transition-colors"
          >
            Join the Waitlist!!
            <ArrowRight className="ml-2" size={20} />
          </a>
        </div>
        </div>

    </div>
  );
};

export default Home;