// src/hooks/useData.js
import { useState, useEffect } from 'react';

export const useData = (fileName) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Try to get the base URL, fallback to relative path if needed
        const isLocalFile = window.location.protocol === 'file:';
        const jsonPath = isLocalFile ? `./data/${fileName}.json` : `/data/${fileName}.json`;

        const response = await fetch(jsonPath);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again.');
        setLoading(false);
      }
    };

    fetchData();
  }, [fileName]);

  return { data, loading, error };
};