// src/pages/Blog.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Clock, Tag, ArrowRight } from 'lucide-react';
import { useData } from 'hooks/userData.js';

const Blog = () => {
  const { data, loading, error } = useData('blog');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  // Get blog description - handle both string and array content
  const getBlogDescription = (blog) => {
    if (blog.description) return blog.description;
    
    if (Array.isArray(blog.content)) {
      const firstParagraph = blog.content.find(item => item.type === 'paragraph');
      return firstParagraph ? firstParagraph.text : '';
    }
    
    return '';
  };

  // Sort blogs by date (latest first)
  const sortedBlogs = React.useMemo(() => {
    if (!data?.blogs) return [];
    
    return [...data.blogs].sort((a, b) => {
      const dateA = new Date(a.date.split('-').join('/'));
      const dateB = new Date(b.date.split('-').join('/'));
      return dateB - dateA;
    });
  }, [data?.blogs]);

  // Filter blogs by category after sorting
  const filteredBlogs = selectedCategory === 'All'
    ? sortedBlogs
    : sortedBlogs.filter(blog => blog.category === selectedCategory);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-50 to-blue-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Our Blog
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Latest insights, updates, and stories about SaaS and technology
            </p>
          </div>
        </div>
      </div>

      {/* Category Filter */}
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-wrap gap-4 justify-center mb-8">
          <button
            onClick={() => setSelectedCategory('All')}
            className={`px-4 py-2 rounded-md ${
              selectedCategory === 'All'
                ? 'bg-indigo-600 text-white'
                : 'bg-white text-gray-600 hover:bg-indigo-50'
            } transition-colors`}
          >
            All
          </button>
          {data?.categories?.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-md ${
                selectedCategory === category
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-indigo-50'
              } transition-colors`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Blog Grid */}
      <div className="max-w-6xl mx-auto px-4 py-8">
        {filteredBlogs.length === 0 ? (
          <div className="text-center text-gray-600 py-12">
            No blogs found in this category.
          </div>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredBlogs.map((blog) => (
              <article
                key={blog.id}
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden group relative"
              >
                <Link to={`/blog/${blog.id}-${blog.titleurl}`} className="block">
                  <div className="aspect-video bg-gray-100 overflow-hidden relative">
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                      onError={(e) => {
                        e.target.src = '/images/placeholder.jpg';
                      }}
                    />
                    {/* Overlay that appears on hover */}
                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center">
                      <div className="px-4 py-2 bg-indigo-600 text-white rounded-md transform translate-y-8 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300 flex items-center">
                        Read More <ArrowRight className="ml-2" size={16} />
                      </div>
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
                      <span className="flex items-center gap-1">
                        <Calendar size={16} />
                        {formatDate(blog.date)}
                      </span>
                      <span className="flex items-center gap-1">
                        <Clock size={16} />
                        {blog.readTime}
                      </span>
                    </div>
                    <h2 className="text-xl font-semibold mb-2 group-hover:text-indigo-600 transition-colors">
                      {blog.title}
                    </h2>
                    <p className="text-gray-600 mb-4 line-clamp-2">
                      {getBlogDescription(blog)}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-600">By {blog.author}</span>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {blog.tags.slice(0, 2).map((tag, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded"
                          >
                            <Tag size={12} className="mr-1" />
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;