// src/components/layout/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Company</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-600 hover:text-indigo-600">About Us</Link></li>
              <li><Link to="/features" className="text-gray-600 hover:text-indigo-600">Features</Link></li>
             {/* <li><Link to="/launch" className="text-gray-600 hover:text-indigo-600">Pricing</Link></li> */}
            </ul>
          </div>

          {/* Resources */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Resources</h3>
            <ul className="space-y-2">
              <li><Link to="/blog" className="text-gray-600 hover:text-indigo-600">Blog</Link></li>
              <li><a href="#" className="text-gray-600 hover:text-indigo-600">Documentation</a></li>
             {/* <li><a href="#" className="text-gray-600 hover:text-indigo-600">API Reference</a></li> */}
            </ul>
          </div>

          {/* Support */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/contact" className="text-gray-600 hover:text-indigo-600">Contact</Link></li>
              <li><Link to="/contact" className="text-gray-600 hover:text-indigo-600">Help Center</Link></li>
             {/* <li><a href="#" className="text-gray-600 hover:text-indigo-600">Status</a></li> */}
            </ul>
          </div>

          {/* Contact */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p className="text-gray-600">
              Email: contact@unimonk.com<br />
              {/*Phone: (555) 123-4567*/}
            </p>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 text-sm">
              © 2025 UniMonk. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="#" className="text-gray-600 hover:text-indigo-600">Privacy Policy</a>
              <a href="#" className="text-gray-600 hover:text-indigo-600">Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;