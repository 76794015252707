// src/pages/TermsOfService.jsx
import React from 'react';
import { useData } from 'hooks/userData.js';

const TermsOfService = () => {
  const { data, loading, error } = useData('terms');

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-600">Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">{data?.title}</h1>
          <p className="text-gray-600 mb-8">Last Updated: {data?.lastUpdated}</p>

          <div className="space-y-8">
            {data?.sections?.map((section, index) => (
              <div key={index} className="prose max-w-none">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">{section.title}</h2>
                <p className="text-gray-600 mb-4">{section.content}</p>
                {section.items && (
                  <ul className="list-disc pl-6 space-y-2">
                    {section.items.map((item, idx) => (
                      <li key={idx} className="text-gray-600">{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;