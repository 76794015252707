// src/pages/BlogDetail.jsx
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, Clock, Tag, ArrowLeft, Share2 } from 'lucide-react';
import { useData } from 'hooks/userData.js';


const BlogDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useData('blog');

  // Format date in a readable format
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  // Loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  // Find the specific blog post
  const blog = data?.blogs?.find(blog => blog.id === parseInt(id));

  // Blog post not found
  if (!blog) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Blog post not found</div>
      </div>
    );
  }

  // Get position class for image alignment
  const getPositionClass = (position) => {
    switch (position) {
      case 'left':
        return 'float-left mr-6 mb-4';
      case 'right':
        return 'float-right ml-6 mb-4';
      case 'top':
        return 'mb-6';
      case 'bottom':
        return 'mt-6';
      default:
        return 'mb-6'; // Default to top positioning
    }
  };

  // Render image with optional position and caption
  const renderImage = (image, extraClasses = '') => {
    if (!image) return null;

    const imgStyle = { 
      width: image.width || '100%' 
    };
    
    const positionClass = getPositionClass(image.position);

    return (
      <figure className={`${positionClass} ${extraClasses}`}>
        <img
          src={image.src}
          alt={image.alt || 'Blog image'}
          style={imgStyle}
          className="rounded-lg shadow-md"
          onError={(e) => {
            e.target.src = '/images/placeholder.jpg';
          }}
        />
        {image.caption && (
          <figcaption className="text-center text-gray-500 mt-2 italic text-sm">
            {image.caption}
          </figcaption>
        )}
      </figure>
    );
  };

  // Render different content types
  const renderContent = (content) => {
    // Handle the old string content format
    if (typeof content === 'string') {
      return <p className="text-gray-700 whitespace-pre-line mb-6">{content}</p>;
    }

    // Handle array of content blocks
    if (Array.isArray(content)) {
      return content.map((item, index) => {
        switch (item.type) {
          case 'paragraph':
            return (
              <div key={item.id || index} className="mb-8 clearfix">
                {/* Top positioned image */}
                {item.image && item.image.position === 'top' && renderImage(item.image)}
                
                {/* Left or right positioned image */}
                {item.image && (item.image.position === 'left' || item.image.position === 'right') && 
                  renderImage(item.image)}

                {/* Paragraph text */}
                <p className="text-gray-700 leading-relaxed">
                  {item.text}
                </p>

                {/* Bottom positioned image */}
                {item.image && item.image.position === 'bottom' && renderImage(item.image)}
              </div>
            );

          case 'image':
            return renderImage({
              src: item.src,
              alt: item.alt,
              caption: item.caption
            }, 'max-w-3xl mx-auto');

          case 'list':
            return (
              <div key={item.id || index} className="mb-8 clearfix">
                {/* Top positioned image */}
                {item.image && item.image.position === 'top' && renderImage(item.image)}

                {item.title && (
                  <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                )}
                
                <ul className="list-disc pl-6 space-y-2">
                  {item.items.map((listItem, i) => (
                    <li key={i} className="text-gray-700">{listItem}</li>
                  ))}
                </ul>

                {/* Bottom positioned image */}
                {item.image && item.image.position === 'bottom' && renderImage(item.image)}
              </div>
            );

          case 'table':
            return (
              <div key={item.id || index} className="mb-8 overflow-x-auto clearfix">
                {item.title && (
                  <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                )}

                {/* Top positioned image */}
                {item.image && item.image.position === 'top' && renderImage(item.image)}

                <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                  <thead className="bg-gray-50">
                    <tr>
                      {item.headers.map((header, i) => (
                        <th 
                          key={i} 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {item.rows.map((row, rowIndex) => (
                      <tr 
                        key={rowIndex} 
                        className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        {row.map((cell, cellIndex) => (
                          <td 
                            key={`cell-${rowIndex}-${cellIndex}`} 
                            className="px-6 py-4 text-sm text-gray-700 border-r last:border-r-0"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Bottom positioned image */}
                {item.image && item.image.position === 'bottom' && renderImage(item.image)}
              </div>
            );

          default:
            return null;
        }
      });
    }

    return null;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Image */}
      <div className="relative h-[60vh] max-h-[600px] min-h-[400px] w-full">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.src = '/images/placeholder.jpg';
          }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="max-w-4xl mx-auto px-4 text-center text-white">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">{blog.title}</h1>
            <div className="flex items-center justify-center gap-6 text-sm mb-4">
              <span className="flex items-center gap-2">
                <Calendar size={18} />
                {formatDate(blog.date)}
              </span>
              <span className="flex items-center gap-2">
                <Clock size={18} />
                {blog.readTime}
              </span>
            </div>
            <p className="text-xl text-gray-200">By {blog.author}</p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        {/* Navigation */}
        <div className="flex justify-between items-center mb-8">
          <Link
            to="/blog"
            className="inline-flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <ArrowLeft size={20} className="mr-2" />
            Back to Blog
          </Link>
          <button
            onClick={() => {
              if (navigator.share) {
                navigator.share({
                  title: blog.title,
                  text: blog.description,
                  url: window.location.href,
                });
              }
            }}
            className="inline-flex items-center text-gray-600 hover:text-indigo-600"
          >
            <Share2 size={20} className="mr-2" />
            Share
          </button>
        </div>

        {/* Tags */}
        <div className="flex flex-wrap gap-2 mb-8">
          {blog.tags.map((tag, index) => (
            <span
              key={index}
              className="inline-flex items-center text-sm bg-gray-100 text-gray-600 px-3 py-1 rounded"
            >
              <Tag size={14} className="mr-1" />
              {tag}
            </span>
          ))}
        </div>

        {/* Description */}
        <p className="text-lg text-gray-700 mb-8 leading-relaxed font-medium">
          {blog.description}
        </p>

        {/* Content */}
        <div className="prose max-w-none">
          {renderContent(blog.content)}
        </div>

        {/* Author Section */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center">
              <span className="text-2xl font-bold text-indigo-600">
                {blog.author.charAt(0)}
              </span>
            </div>
            <div>
              <h3 className="text-lg font-semibold">Written by {blog.author}</h3>
              <p className="text-gray-600">{blog.category} Expert</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;