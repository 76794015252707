import React, { useState } from 'react';
//import { useData } from '../hooks/useData';
import { useData } from 'hooks/userData.js';
import { Mail, Phone, MapPin, Send } from 'lucide-react';


const Contact = () => {
  const { data, loading, error } = useData('contact');

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">Error: {error}</div>;
  }

  // Extract email and office information
  const emailInfo = data?.contactInfo?.find(info => info.type === 'email');
  const officeInfo = data?.contactInfo?.find(info => info.type === 'address');

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {data?.title || "Get in Touch"}
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            {data?.description || "Have questions? We'd love to hear from you."}
          </p>
        </div>

        {/* Contact Information with Grid Layout */}
        <div className="max-w-3xl mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-sm">
            {/* Grid for perfect alignment */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              {/* Email Information */}
              {emailInfo && (
                <div className="flex items-start">
                  <div className="bg-indigo-100 p-3 rounded-full mr-4 flex-shrink-0">
                    <Mail className="text-indigo-600" size={28} />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">{emailInfo.title}</h3>
                    <p className="text-lg text-indigo-600">{emailInfo.value}</p>
                    {emailInfo.description && (
                      <p className="text-gray-500 text-sm">{emailInfo.description}</p>
                    )}
                  </div>
                </div>
              )}

              {/* Office Information */}
              {officeInfo && (
                <div className="flex items-start">
                  <div className="bg-indigo-100 p-3 rounded-full mr-4 flex-shrink-0">
                    <MapPin className="text-indigo-600" size={28} />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">{officeInfo.title}</h3>
                    <p className="text-lg text-indigo-600">{officeInfo.value}</p>
                    {officeInfo.description && (
                      <p className="text-gray-500 text-sm">{officeInfo.description}</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Support Hours */}
            {data?.supportHours && (
              <div className="mt-12 border-t border-gray-200 pt-8">
                <h3 className="text-xl font-semibold mb-6 text-center">Support Hours</h3>
                <div className="grid md:grid-cols-3 gap-4 text-center">
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">Weekdays</h4>
                    <p className="text-gray-600">{data.supportHours.weekdays}</p>
                  </div>
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">Weekends</h4>
                    <p className="text-gray-600">{data.supportHours.weekends}</p>
                  </div>
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">Holidays</h4>
                    <p className="text-gray-600">{data.supportHours.holidays}</p>
                  </div>
                </div>
              </div>
            )}

            {/* Social Media */}
            {data?.socialMedia && (
              <div className="mt-12 border-t border-gray-200 pt-8">
                <h3 className="text-xl font-semibold mb-6 text-center">Find Us Online</h3>
                <div className="flex justify-center space-x-8">
                  {data.socialMedia.twitter && (
                    <a href={`https://twitter.com/${data.socialMedia.twitter}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                      Twitter
                    </a>
                  )}
                  {data.socialMedia.linkedin && (
                    <a href={`https://linkedin.com/in/${data.socialMedia.linkedin}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                      LinkedIn
                    </a>
                  )}
                  {data.socialMedia.github && (
                    <a href={`https://github.com/${data.socialMedia.github}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                      </svg>
                      GitHub
                    </a>
                  )}
                </div>
              </div>
            )}

            {/* Note about contact form */}
            <div className="mt-12 border-t border-gray-200 pt-8 text-center">
              <p className="text-gray-600">
                Contact form coming soon! In the meantime, please reach out to us using the contact information above.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;