// src/pages/About.jsx
import React from 'react';
import { Users, Building, Award, Brain, Globe2, Target } from 'lucide-react';
//import { useData } from '../hooks/useData.js';
import { useData } from 'hooks/userData.js';


const iconMap = {
  Users: Users,
  Building: Building,
  Award: Award,
  Brain: Brain,
  Globe2: Globe2,
  Target: Target
}

const getIcon = (iconName) => {
  const IconComponent = iconMap[iconName] || Award;
  return <IconComponent className="h-6 w-6 text-indigo-600 group-hover:text-white transition-colors duration-300" />;
};

const About = () => {
  const { data, loading, error } = useData('about');
  
  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-50 to-blue-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              {data?.hero?.title || "About Our Company"}
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              {data?.hero?.description || "Building the future of SaaS platforms"}
            </p>
          </div>
        </div>
      </div>

      {/* Company Stats */}
      <div className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            {data?.stats?.map((stat, index) => (
              <div key={index} className="p-6">
                <div className="text-4xl font-bold text-indigo-600 mb-2">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Team Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Our Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {data?.team?.map((member, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <div className="w-24 h-24 rounded-full bg-indigo-100 mx-auto mb-4 flex items-center justify-center">
                  <Users className="text-indigo-600" size={32} />
                </div>
                <h3 className="text-xl font-semibold text-center mb-2">{member.name}</h3>
                <p className="text-gray-600 text-center font-medium mb-2">{member.title}</p>
                <p className="text-gray-600 text-center font-medium mb-2">{member.position}</p>
                <p className="text-gray-500 text-center mt-2">{member.bio}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Our Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {data?.values?.map((value, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 rounded-full bg-indigo-100 mx-auto mb-4 flex items-center justify-center">
                {getIcon(value.icon)}
                </div>
                <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;