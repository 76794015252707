import React from 'react';
//import { useData } from '../hooks/useData';
import { useData } from 'hooks/userData.js';
//import { Rocket, Check, ArrowRight } from 'lucide-react';

// src/pages/Features.jsx
//import React from 'react';
//import { useData } from '../hooks/useData';
import { 
  Cloud, 
  BarChart2, 
  NotebookPen,
  GraduationCap,
  Shield, 
  Users, 
  Zap, 
  Settings, 
  Code, 
  Database,
  Lock
} from 'lucide-react';

const iconMap = {
  Cloud: Cloud,
  GraduationCap: GraduationCap,
  Analytics: BarChart2,
  Security: Shield,
  Users: Users,
  NotebookPen: NotebookPen,
  Performance: Zap,
  Integration: Settings,
  Development: Code,
  Database: Database,
  Privacy: Lock
};

const Features = () => {
  const { data, loading, error } = useData('features');

  const getIcon = (iconName) => {
    const IconComponent = iconMap[iconName] || Cloud;
    return <IconComponent className="h-6 w-6 text-indigo-600 group-hover:text-white transition-colors duration-300" />;
  };

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-600">Error: {error}</div>;
  }

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-50 to-blue-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              {data?.hero?.title || "Our Features"}
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              {data?.hero?.description}
            </p>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {data?.features?.map((feature) => (
              <div 
                key={feature.id} 
                className="group p-6 bg-white rounded-lg shadow-sm hover:shadow-xl 
                          hover:bg-indigo-600 cursor-pointer transform hover:-translate-y-1 
                          transition-all duration-300 ease-in-out"
              >
                <div className="w-12 h-12 bg-indigo-50 group-hover:bg-indigo-100 rounded-lg 
                              flex items-center justify-center mb-4 transition-colors duration-300">
                  {getIcon(feature.icon)}
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900 group-hover:text-indigo-300 
                              transition-colors duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-600 group-hover:text-indigo-100 transition-colors duration-300">
                  {feature.description}
                </p>
                {feature.benefits && (
                  <ul className="mt-4 space-y-2">
                    {feature.benefits.map((benefit, index) => (
                      <li 
                        key={index} 
                        className="flex items-start gap-2 text-gray-600 group-hover:text-indigo-100 
                                  transition-colors duration-300"
                      >
                        <span className="text-indigo-600 group-hover:text-white transition-colors 
                                      duration-300 mt-1">•</span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Integrations Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Integrations</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
            {data?.integrations?.map((integration, index) => (
              <div 
                key={index} 
                className="text-center p-4 bg-white rounded-lg shadow-sm hover:shadow-md 
                          hover:bg-indigo-50 transition-all duration-300"
              >
                <p className="font-medium">{integration}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;